.ExchangeBanner {
  background: linear-gradient(90deg, #105461, #28156e);
  border-radius: 0.5rem;
  margin: 3rem 3.1rem 1.5rem;
  padding: 1.2rem 2.4rem;
  padding-right: 4rem;
  position: relative;
}

.ExchangeBanner-text {
  color: white;
  font-size: 1.8rem;
  line-height: 2.3rem;
  margin: 0;
}

.ExchangeBanner-price {
  color: #50d0fe;
}

.ExchangeBanner-link {
  color: white;
}

.ExchangeBanner-close {
  position: absolute;
  top: 1.2rem;
  right: 2.4rem;
  cursor: pointer;
}

@media (max-width: 700px) {
  .ExchangeBanner {
    margin: 1.5rem;
    margin-top: 0.8rem;
  }
}
