.chart-token-selector {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.chart-token-selector--current {
  font-size: 2.1rem;
  font-weight: 700;
  margin-right: 1rem;
  color: white;
}

.chart-token-menu-items.menu-items {
  width: 15.8rem;
  top: 6rem;
  right: unset;
  left: 0;
}

.chart-token-menu-items .menu-item {
  font-size: var(--font-sm);
  height: 3.4rem;
  color: #a0a3c4;
}
