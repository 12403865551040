.event-toast-container .toast-links {
  margin-top: 1.5rem;
  font-size: var(--font-sm);
  padding: 0 1.5rem 1.5rem 1.5rem;
}
.zoomIn {
  animation: zoomIn 0.2s ease-in-out;
  animation-fill-mode: forwards;
}
.zoomOut {
  animation: zoomOut 0.2s ease-in-out;
  animation-fill-mode: forwards;
}
.event-toast-container .toast-links a {
  text-decoration: underline;
  font-size: var(--font-sm);
  opacity: 0.7;
  margin-right: 1.5rem;
}
.event-toast-container .toast-links a:hover {
  opacity: 1;
}

.single-toast {
  background: rgba(43, 49, 85, 0.85);
  backdrop-filter: blur(4px);
  color: white;
  border: none;
  position: relative;
  border-radius: 4px;
  border: 1px solid #ffffff29;
  width: 35rem;
}
.single-toast header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1.5rem;
  border-bottom: 1px solid #ffffff29;
}
.single-toast header .toast-title {
  display: flex;
  align-items: center;
}
.single-toast header .cross-icon {
  width: 2.2rem;
  height: 2.2rem;
  cursor: pointer;
  border-radius: 4px;
  padding: 2px;
  margin-left: 1.5rem;
}
.single-toast header .cross-icon:hover {
  background: #ffffff47;
}
.single-toast header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.single-toast header .announcement-icon {
  width: 2rem;
  height: 2rem;
  color: white;
}
.single-toast header p {
  margin: 0;
  padding: 0.3875rem 0;
  margin-left: 1.1625rem;
  font-size: var(--font-md);
}

.single-toast .toast-body {
  margin: 0.8rem 0;
  padding: 0;
  font-size: var(--font-sm);
  line-height: 1.5;
  padding: 0 1.5rem;
}

@media screen and (max-width: 500px) {
  .event-toast-container {
    inset: 8rem 1.6rem 0 1.6rem;
  }
  .single-toast {
    background: rgba(43, 49, 85, 1);
  }
}
